import { useState } from "react"
import styled from "styled-components/macro"

import { intlFormat, parseISO } from "date-fns"

import { MChip } from "src/components/MChip/MChip"
import { MPagination } from "src/components/Pagination/MPagination"
import { MTable } from "src/components/Table/MTable"
import { MAX_LIMIT } from "src/data/constants/constants"
import { useFetchLoans } from "src/data/loans/loanQueries"
import { useFetchUsers } from "src/data/users/userQueries"
import { LoadingScreen } from "src/ui/LoadingScreen/LoadingScreen"
import { Caption } from "src/ui/typography"
import { statusToColorProfile } from "src/utils/utils"

export function LoansList() {
  const limit = MAX_LIMIT
  const [offset, setOffset] = useState(0)
  const fetchLoans = useFetchLoans({ skip: offset, limit })
  const loans = fetchLoans.data?.loans || []

  const userIds = loans.map((loan) => loan.userId) || []

  const fetchUsers = useFetchUsers({
    userIds,
    options: { enabled: userIds.length > 0 },
  })

  const users = fetchUsers.data?.users || []

  const headerData = [
    "Name",
    "Amount (UGX)",
    "Interest rate p/a",
    "Amortisation (UGX)",
    "Principle (UGX)",
    "Status",
    "Comment",
  ]
  const header = headerData.map((item) => <div>{item}</div>)
  const rows = loans.map((loan) => {
    const loanDate = intlFormat(new Date(parseISO(loan.createdAt)), {
      year: "numeric",
      month: "long",
      day: "numeric",
    })

    const user = users.find((user) => user.userId === loan.userId)

    return (
      <>
        <NameBox>
          {user?.name || "Unknown user"}
          <Caption>{loanDate}</Caption>
        </NameBox>
        <div>{loan.amount?.toLocaleString()}</div>
        <div>{loan.interestRate} %</div>
        <div>{loan.amortisation?.toLocaleString()}</div>
        <div>{loan.principle.toLocaleString()}</div>
        <div>
          <MChip
            label={loan.loanStatus}
            color={statusToColorProfile(loan.loanStatus)}
          />
        </div>
        <div>{loan.comment}</div>
      </>
    )
  })

  if (fetchLoans.isPending || fetchUsers.isLoading) {
    return <LoadingScreen />
  }

  return (
    <MTable
      title="Status"
      header={header}
      rows={rows}
      templateColumns={`repeat( 7, minmax(min-content, 1fr))`}
      pagination={
        <MPagination
          limit={limit}
          offset={offset}
          setOffset={setOffset}
          totalCount={fetchLoans.data?.totalCount || 0}
        />
      }
    />
  )
}

const NameBox = styled.div`
  display: flex;
  min-width: max-content;
`
